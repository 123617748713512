<template lang="pug">
  v-simple-table
    template(v-slot:default)
      thead(class="ma-0 pa-0")
        tr(class="ma-0 pa-0")
          th(class="ma-0 pa-0" colspan=4)
            span {{ desc }}
      tbody(class="text-left")
        tr(v-for="item in weventList" :key="item.id" class="text-left")
          td(class="text-left")
            span {{ weventKind(item.type).description }}
          td(class="text-left")
            span {{ $moment(item.time_start * 1000).tz(zone.timezone).format("DD/MM HH:mm") }} - {{ $moment(item.time_end * 1000).tz(zone.timezone).format("DD/MM HH:mm") }}
          td(class="text-left")
            span(v-if="item.cat") {{ item.value_min }} - {{ item.value_max }} {{ item.cat.unit }}
          td
            span(v-if="item.is_alert")
              v-icon(:color="gWeventColorSeverity(item.severity)") {{ gWeventIcon(item.type) }}
</template>
<script>

export default {
  props: {
    zone: {
      type: Object,
      default: null
    },
    weventList: {
      type: Array,
      default: null
    },
    desc: {
      type: String,
      default: null
    }
  }
}
</script>
<style>
</style>
