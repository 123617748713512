<template lang="pug">
v-container(fluid)
  l-map(ref="map" :center="[47, 2]" :zoom="4" style="height: 90vh;z-index:2;" @update:zoom="updateZoom()" @ready="fitBoundOnMap")
    l-tile-layer(:url="url")
    l-feature-group(:visible="showCastpointMarker")
      l-marker(
          v-for="(cp, b) in castpoints"
          :key="`marker-castpoint-${b}`"
          :lat-lng="[cp.latitude, cp.longitude]"
          @click="gForecastCastpoint(cp.id)"
      )
        l-tooltip(:options="{direction:'bottom'}")
          span {{ cp.name }}
        l-icon(:icon-anchor="[7,7]" :tooltip-anchor="[0,11]")
          div.marker-castpoint(:style="`background-color:${gColorCastpointMarker(cp)}`" )
    l-marker(
      v-for="(zone, b) in castzones"
      v-if="showTitleZone && (zone.parent == null || arianeid.includes(zone.id) || arianeid.includes(zone.parent.id))"
      :key="`marker${b}`"
      :lat-lng="centroidOf(zone.geometry.coordinates)"
      @click="clickZone(zone, true)"
      )
      l-tooltip(:options="{direction:'bottom'}")
        span 
          | {{ zone.name }}
          w-event-info-list(v-if="weventsPublishDirect(zone).length" :zone="zone" :weventList="weventsPublishDirect(zone)" :desc="$t('label.published')")
          w-event-info-list(v-if="weventsPublishInherit(zone).length" :zone="zone" :weventList="weventsPublishInherit(zone)" :desc="$t('label.published')")
          w-event-info-list(v-if="weventsDraftDirect(zone).length" :zone="zone" :weventList="weventsDraftDirect(zone)" :desc="$t('label.draft')")
          w-event-info-list(v-if="weventsDraftInherit(zone).length" :zone="zone" :weventList="weventsDraftInherit(zone)" :desc="$t('label.draft')")
      l-icon(:icon-anchor="[16,16]" :tooltip-anchor="[0,22]")
        v-btn(fab x-small)
          | {{ zone.short_name }}
    l-geo-json(
      v-for="(zone, b) in castzones" :key="b"
      v-if="zone.parent == null || arianeid.includes(zone.id) || arianeid.includes(zone.parent.id)"
      :options-style="gStyleZoneGeoJson(zone)"
      :geojson="zone.geometry"
      @click="clickZone(zone)"
      )
</template>

<script>
import { LFeatureGroup, LTooltip, LMap, LTileLayer, LGeoJson, LMarker, LIcon, LControl } from 'vue2-leaflet'
import { mapState } from 'vuex'
import * as turf from '@turf/turf'
import moment from 'moment-timezone'
import WEventInfoList from '@/components/Castzones/WEventInfoList'

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
    LIcon,
    LControl,
    LTooltip,
    LFeatureGroup,
    WEventInfoList
  },
  data() {
    return {
      tab: 0,
      search: '',
      moment: moment,
      map: null,
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      weventsFilterActive: true
    }
  },
  computed: {
    ...mapState(['castpoints','castzones','castzonesOrder', 'gotoZone', 'ariane']),
    showTitleZone() { return this.zoom > 7 },
    showCastpointMarker() { return this.zoom > 7 },
    smallBtnZone() { return this.zoom < 6 },
    arianeid() { return this.ariane.map(a => a.id) },
    weventsFiltered() { 
      return function(zone) {
        return this.weventsFilterActive ? this.gGetWevents(zone).filter(w => (w.is_active && w.will_be_active == null) || (w.will_be_active) ) : this.gGetWevents(zone)
      }
    },
    weventsPublishDirect() { 
      return function(zone) {
        return this.weventsFiltered(zone).filter(w => !w.is_draft && !w.inherit)
      }
    },
    weventsPublishInherit() { 
      return function(zone) {
        return this.weventsFiltered(zone).filter(w => !w.is_draft && w.inherit)
      }
    },
    weventsDraftDirect() {
      return function(zone) {
        return this.weventsFiltered(zone).filter(w => w.is_draft && !w.inherit) 
      }
    },
    weventsDraftInherit() {
      return function(zone) {
        return this.weventsFiltered(zone).filter(w => w.is_draft && w.inherit) 
      }
    },
  },
  watch: {
    'currentZoneId': function() {
      this.currentZoneId && this.fitCurrentZone()
    }
  },
  methods: {
    updateSearch(value) {
      this.gSetKey('filter_search', value)
    },
    openZone(zone) {
      this.gLoadZone({id: zone.id, show: true, route: true})
    },
    fitCurrentZone() {
      if(!this.currentZone || !this.currentZone.bounds || this.currentZone.bounds.length == 0) {
        let geometry = this.ariane[0].geometry
        const bbox = turf.bbox(geometry)
        const bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]] ]
        this.$refs.map && this.$refs.map.mapObject.fitBounds(bounds, {maxZoom: 11})
      }
      else {
        try {
          var polygon = turf.polygon(this.currentZone.geometry)
          var center = turf.centroid(polygon.geometry.coordinates).geometry.coordinates.reverse()
          this.$refs.map && this.$refs.map.mapObject.panTo(center)
        }
            // eslint-disable-next-line no-empty
        catch (e) {
        }
      }
    },
    updateZoom() {
      this.zoom = this.$refs.map.mapObject.getZoom()
    },
    centroidOf(geom) {
      var polygon = turf.polygon(geom[0])
      var centroid = turf.centroid(polygon)
      return centroid.geometry.coordinates.reverse()
    },
    clickZone(zone, open=false) {
      this.updateSearch(null)
      this.$nextTick(()=>{
        this.gLoadZone({id:zone.id,show:open,route:open})
        this.gSetLastAriane(zone)
      })
    },
    fitBoundOnMap() {
      setTimeout(() => this.fitCurrentZone(), 10)
    },

  },
}
</script>

<style>
.marker-castpoint {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
</style>
